<template>
  <div class="d-flex flex-row search-input-container">
    <v-combobox
      class="search-input pr-0"
      :class="$vuetify.breakpoint.smAndDown ? 'small rounded-lg' : 'big'"
      clearable
      filled
      solo
      dense
      :height="$vuetify.breakpoint.smAndDown ? 43 : 48"
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      no-filter
      :label="
        isListening
          ? $t('navbar.search.speechActive')
          : $t('navbar.search.searchPlaceholder')
      "
      :placeholder="
        isListening
          ? $t('navbar.search.speechActive')
          : $t('navbar.search.searchPlaceholder')
      "
      flat
      item-text="name"
      item-value="name"
      hide-details
      hide-no-data
      return-object
      type="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
      :menu-props="{
        closeOnContentClick: true,
        contentClass: 'searches-menu'
      }"
    >
      <template v-slot:item="{ item }">
        <div
          class="d-flex w-100 primary--text"
          :class="item.type == 'FastSearch' ? 'fast-search-btn' : ''"
        >
          <v-list-item-icon
            v-if="item.type == 'Categorie'"
            class="search-category-img"
          >
            <img
              :src="getImage(item)"
              onerror="this.src='/no-icon.png'"
              class="category-image"
            />
          </v-list-item-icon>
          <v-list-item-icon v-else-if="item.type == 'Ricerche'">
            <v-icon @click.prevent.stop="deleteWord(item)" small>
              $close
            </v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else-if="item.type == 'FastSearch'">
            <v-icon color="primary">$fastSearch</v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon small>$search</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            @click="
              item.type == 'FastSearch'
                ? openFastSearchDialog()
                : doSearch(item)
            "
          >
            <v-list-item-title>
              <span
                class="font-weight-bold primary--text text-body-2"
                v-if="item.type == 'FastSearch'"
              >
                {{ $t("fastSearch.searchInput.label") }}
              </span>
              <text-highlight v-else :queries="search ? search : '*'">
                {{ item.name }}
              </text-highlight>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              Cerca <strong>{{ search }}</strong> in {{ item.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="my-0">
            <div class="badge" v-if="item.count">
              {{ item.count }}
            </div>
            <v-icon
              v-if="item.type == 'Ricerche'"
              @click.prevent.stop="search = item.name"
              small
            >
              $arrowTopLeft
            </v-icon>
          </v-list-item-action>
        </div>
      </template>
      <template slot="prepend-inner">
        <Barcode v-if="isCordova" @input="doSearch" />
        <v-icon
          v-else
          aria-label="Cerca"
          @click.prevent.stop="doSearch"
          color="black"
          >$search</v-icon
        >
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp" slot="append">
        <v-btn
          depressed
          @click.prevent.stop="doSearch"
          height="48px"
          color="grey lighten-2"
          >{{ $t("navbar.search.searchBtn") }}</v-btn
        >
      </template>
    </v-combobox>
  </div>
</template>
<style lang="scss">
.search-input-container {
  .v-autocomplete {
    &.search-input {
      border: 1px solid var(--v-grey-base);
    }
    .v-input__prepend-outer {
      align-self: center;
      margin-left: 10px;
      margin-right: 0px;
      padding-right: 4px;
    }
    .v-input__icon--append {
      display: none;
    }
    ::placeholder,
    .v-label {
      color: #828282 !important;
    }
    .v-input__slot {
      height: 48px;
      padding-right: 0px !important;
      border: 1px solid (--v-grey-base);
      .v-label {
        top: calc(50% - 9px) !important;
      }
    }
  }
  .v-autocomplete__content {
    .v-list--dense {
      .v-subheader {
        font-size: 16px;
        font-weight: bold;
        color: $primary;
        padding: 0px 16px;
      }
      .v-list-item {
        min-height: 32px;
        .v-list-item__icon {
          padding: 0;
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }
    }
  }
  .search-input.v-select.v-select--is-menu-active {
    .v-input__control {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .search-item {
    min-height: 36px;
  }
}
.searches-menu.v-autocomplete__content.v-menu__content {
  max-height: calc(100vh - 200px) !important;
  border-radius: 0 0 4px 4px;
  border-left: 1px solid var(--v-grey-base);
  border-right: 1px solid var(--v-grey-base);
  border-bottom: 1px solid var(--v-grey-base);
  box-sizing: content-box;
  margin-left: -1px;
  margin-top: -1px;
  box-shadow: 0px 16px 10px -5px rgba(32, 33, 36, 0.28);
  .v-select-list {
    border-radius: 0;
  }
  // .fast-search-btn {
  // border: 2px solid var(--v-primary-base);
  // border-radius: $border-radius-root;
  // }
}
.search-category-img {
  .category-image {
    max-width: 24px;
    max-height: 24px;
  }
}
.is-cordova {
  .v-menu__content.v-autocomplete__content.searches-menu {
    width: 100%;
    left: 0;
    z-index: 10;
  }
}
</style>
<script>
import Barcode from "./Barcode.vue";
import FastSearchMenu from "./FastSearchMenu.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

import LongPress from "vue-directive-long-press";

export default {
  components: {
    Barcode
  },
  data() {
    return {
      isLoadingSuggestions: false,
      enterPressed: false,
      isFocused: null,
      selected: null,
      search: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      },
      speechOptions: {
        language: "it-IT",
        matches: "5",
        prompt: "", // Android only
        showPopup: true, // Android only
        showPartial: false
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.isLoadingSuggestions = true;
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      result.unshift({
        type: "FastSearch",
        name: this.$t("fastSearch.searchInput.label")
      });
      return result;
    }
  },
  directives: {
    "long-press": LongPress
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/img_layout/categories_icon/" + item.id + ".svg";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.isLoadingSuggestions = true;
      if (this.search) {
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let newItems = [];
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          _this.items = newItems;
          _this.isloadingsuggestions = false;
          if (_this.enterPressed) {
            _this.isLoadingSuggestions = false;
            _this.doSearch({
              type: "search"
            });
            _this.enterPressed = false;
            return;
          }
        });
        _this.enterPressed = false;
      }

      _this.isLoadingSuggestions = false;
    }, 200),
    doSearch(item) {
      if (this.isLoadingSuggestions == true) {
        this.enterPressed = true;
      } else {
        this.enterPressed = false;
        this.items = [];

        var currentQuery = {};
        if (item.type === "Suggerimenti") {
          //ho selezionato un nome
          currentQuery.q = item.name;
        } else if (item.type === "Categorie") {
          currentQuery.parent_category_id = item.id;
          currentQuery.filter = item.name;
          currentQuery.q = this.search;
        } else if (item.type === "Ricerche") {
          currentQuery.q = item.name;
        } else if (item.type === "Marche") {
          currentQuery.parent_vendor_id = item.id;
          currentQuery.filter = item.name;
          currentQuery.q = this.search;
        } else if (item.type === "Barcode") {
          currentQuery.barcode = item.name;
        } else if (item.type === "FastSearch") {
          currentQuery.q = "";
        } else if (this.search != null) {
          currentQuery.q = this.search;
          this.saveWord(currentQuery.q);
        }

        if (currentQuery.q || currentQuery.barcode) {
          AnalyticsService.search(this.search);
          //this.$store.dispatch("category/resetFilters");
          global.EventBus.$emit("resetFilters");
          this.$router.push({
            // path: "/search?q=:q&parent_category_id=:parent_category_id",
            path: "/search",
            name: "Search",
            query: currentQuery
          });

          this.$nextTick(() => {
            this.$refs.autocomplete.blur();
            this.search = currentQuery.q;
          });
        }
      }
    },
    onLongPressStart() {
      // triggers after 300ms of mousedown
      this.isListening = true;
      this.startSpeechRecognition();
    },
    onLongPressStop() {
      // triggers on mouseup of document
      this.isListening = false;
      this.stopSpeechRecognition();
    },
    startSpeechRecognition() {
      // Verify if recognition is available
      var _this = this;
      // eslint-disable-next-line no-undef
      // let platform = device ? device.platform.toLowerCase() : "ios";
      window.plugins.speechRecognition.isRecognitionAvailable(
        function(available) {
          if (!available) {
            console.log("Riconoscimento Vocale non disponibile");
          }

          // Check if has permission to use the microphone
          window.plugins.speechRecognition.hasPermission(
            function(isGranted) {
              if (isGranted) {
                window.plugins.speechRecognition.startListening(
                  function(result) {
                    _this.search = result[0];
                    _this.doSearch(result[0]);
                  },
                  function(err) {
                    console.error(err);
                  },
                  _this.speechOptions
                );
              } else {
                // Request the permission
                window.plugins.speechRecognition.requestPermission(
                  function() {
                    // Request accepted, start recognition
                    window.plugins.speechRecognition.startListening(
                      function(result) {
                        // Show results in the console
                        _this.search = result[0];

                        _this.doSearch(result[0]);
                      },
                      function(err) {
                        console.error(err);
                      },
                      _this.speechOptions
                    );
                  },
                  function(err) {
                    console.log(err);
                  }
                );
              }
            },
            function(err) {
              console.log(err);
            }
          );
        },
        function(err) {
          console.log(err);
        }
      );
    },
    async openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = await this.$dialog.show(FastSearchMenu, config);
      this.search = null;
      this.selected = null;
    },
    stopSpeechRecognition() {
      window.plugins.speechRecognition.stopListening(
        function() {
          // No more recognition
        },
        function(err) {
          console.log(err);
        }
      );
    }
    // scan() {
    //   if (window.cordova && window.cordova.plugins.barcodeScanner) {
    //     try {
    //       this.isloadingsuggestions = true;
    //       // eslint-disable-next-line no-undef
    //       cordova.plugins.barcodeScanner.scan(
    //         result => {
    //           if (result) {
    //             this.isloadingsuggestions = false;
    //             this.$emit("input", {
    //               type: "Barcode",
    //               name: result.text
    //             });
    //           }
    //         },
    //         error => {
    //           this.isloadingsuggestions = false;
    //           console.error("Scanning failed", error);
    //         },
    //         {
    //           showFlipCameraButton: true,
    //           showTorchButton: true,
    //           resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    //           formats:
    //             this.formats ||
    //             "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
    //         }
    //       );
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
